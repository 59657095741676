<template>
  <div
    class  = "vs-sidebar-group"
    :class = "[
      {'vs-sidebar-group-open'            : openItems        },
      {'vs-sidebar-group-active'          : open             },
      {'disabled-item pointer-events-none': group.isDisabled }
    ]"
    @mouseover = "mouseover"
    @mouseout  = "mouseout">
      <!-- {{activeUserInfos.privileges}} -->
      <!-- {{group.i18n}} -->
      <!-- Group Label -->
      <template v-if="group.i18n === 'Paramétrage'">
        <template v-if="activeUserInfos.privileges.find(item => item === 'parametrage')">
          <div @click="clickGroup" class="group-header w-full">
            <span class="flex items-center w-full">

              <!-- Group Icon -->
              <feather-icon
                v-if        = "group.icon  || (this.groupIndex > Math.floor(this.groupIndex))"
                :icon       = "group.icon  || 'CircleIcon'"
                :svgClasses = "{ 'w-3 h-3' : this.groupIndex % 1 != 0 }" />

              <!-- Group Name -->
              <span v-show="!verticalNavMenuItemsMin" class="truncate mr-3 select-none">{{ $t(group.i18n) || group.name }}</span>

              <!-- Group Tag -->
              <vs-chip class="ml-auto mr-4" :color="group.tagColor" v-if="group.tag && !verticalNavMenuItemsMin">{{ group.tag }}</vs-chip>
            </span>

            <!-- Group Collapse Icon -->
            <feather-icon
              v-show     = "!verticalNavMenuItemsMin"
              :class     = "[{'rotate90' : openItems}, 'feather-grp-header-arrow']"
              :icon       = "$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
              svg-classes= "w-4 h-4" />

            <!-- Group Tooltip -->
            <span class="vs-sidebar--tooltip">{{ $t(group.i18n) || group.name }}</span>
          </div>

        </template>
      </template>
      <template v-if="group.i18n === 'Commercial'">
        <template v-if="activeUserInfos.privileges.find(item => item === 'commercial')">
          <div @click="clickGroup" class="group-header w-full">
            <span class="flex items-center w-full">

              <!-- Group Icon -->
              <feather-icon
                v-if        = "group.icon  || (this.groupIndex > Math.floor(this.groupIndex))"
                :icon       = "group.icon  || 'CircleIcon'"
                :svgClasses = "{ 'w-3 h-3' : this.groupIndex % 1 != 0 }" />

              <!-- Group Name -->
              <span v-show="!verticalNavMenuItemsMin" class="truncate mr-3 select-none">{{ $t(group.i18n) || group.name }}</span>

              <!-- Group Tag -->
              <vs-chip class="ml-auto mr-4" :color="group.tagColor" v-if="group.tag && !verticalNavMenuItemsMin">{{ group.tag }}</vs-chip>
            </span>

            <!-- Group Collapse Icon -->
            <feather-icon
              v-show     = "!verticalNavMenuItemsMin"
              :class     = "[{'rotate90' : openItems}, 'feather-grp-header-arrow']"
              :icon       = "$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
              svg-classes= "w-4 h-4" />

            <!-- Group Tooltip -->
            <span class="vs-sidebar--tooltip">{{ $t(group.i18n) || group.name }}</span>
          </div>

        </template>
      </template>
      <template v-if="group.i18n === 'Gestion de stock'">
        <template v-if="activeUserInfos.privileges.find(item => item === 'stock')">
          <div @click="clickGroup" class="group-header w-full">
            <span class="flex items-center w-full">

              <!-- Group Icon -->
              <feather-icon
                v-if        = "group.icon  || (this.groupIndex > Math.floor(this.groupIndex))"
                :icon       = "group.icon  || 'CircleIcon'"
                :svgClasses = "{ 'w-3 h-3' : this.groupIndex % 1 != 0 }" />

              <!-- Group Name -->
              <span v-show="!verticalNavMenuItemsMin" class="truncate mr-3 select-none">{{ $t(group.i18n) || group.name }}</span>

              <!-- Group Tag -->
              <vs-chip class="ml-auto mr-4" :color="group.tagColor" v-if="group.tag && !verticalNavMenuItemsMin">{{ group.tag }}</vs-chip>
            </span>

            <!-- Group Collapse Icon -->
            <feather-icon
              v-show     = "!verticalNavMenuItemsMin"
              :class     = "[{'rotate90' : openItems}, 'feather-grp-header-arrow']"
              :icon       = "$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
              svg-classes= "w-4 h-4" />

            <!-- Group Tooltip -->
            <span class="vs-sidebar--tooltip">{{ $t(group.i18n) || group.name }}</span>
          </div>

        </template>
      </template>
      <template v-if="group.i18n === 'Exploitation'">
        <template v-if="activeUserInfos.privileges.find(item => item === 'exploitation')">
          <div @click="clickGroup" class="group-header w-full">
            <span class="flex items-center w-full">

              <!-- Group Icon -->
              <feather-icon
                v-if        = "group.icon  || (this.groupIndex > Math.floor(this.groupIndex))"
                :icon       = "group.icon  || 'CircleIcon'"
                :svgClasses = "{ 'w-3 h-3' : this.groupIndex % 1 != 0 }" />

              <!-- Group Name -->
              <span v-show="!verticalNavMenuItemsMin" class="truncate mr-3 select-none">{{ $t(group.i18n) || group.name }}</span>

              <!-- Group Tag -->
              <vs-chip class="ml-auto mr-4" :color="group.tagColor" v-if="group.tag && !verticalNavMenuItemsMin">{{ group.tag }}</vs-chip>
            </span>

            <!-- Group Collapse Icon -->
            <feather-icon
              v-show     = "!verticalNavMenuItemsMin"
              :class     = "[{'rotate90' : openItems}, 'feather-grp-header-arrow']"
              :icon       = "$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
              svg-classes= "w-4 h-4" />

            <!-- Group Tooltip -->
            <span class="vs-sidebar--tooltip">{{ $t(group.i18n) || group.name }}</span>
          </div>

        </template>
      </template>
      <template v-if="group.i18n === 'Caisse'">
        <template v-if="activeUserInfos.privileges.find(item => item === 'caisse')">
          <div @click="clickGroup" class="group-header w-full">
            <span class="flex items-center w-full">

              <!-- Group Icon -->
              <feather-icon
                v-if        = "group.icon  || (this.groupIndex > Math.floor(this.groupIndex))"
                :icon       = "group.icon  || 'CircleIcon'"
                :svgClasses = "{ 'w-3 h-3' : this.groupIndex % 1 != 0 }" />

              <!-- Group Name -->
              <span v-show="!verticalNavMenuItemsMin" class="truncate mr-3 select-none">{{ $t(group.i18n) || group.name }}</span>

              <!-- Group Tag -->
              <vs-chip class="ml-auto mr-4" :color="group.tagColor" v-if="group.tag && !verticalNavMenuItemsMin">{{ group.tag }}</vs-chip>
            </span>

            <!-- Group Collapse Icon -->
            <feather-icon
              v-show     = "!verticalNavMenuItemsMin"
              :class     = "[{'rotate90' : openItems}, 'feather-grp-header-arrow']"
              :icon       = "$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
              svg-classes= "w-4 h-4" />

            <!-- Group Tooltip -->
            <span class="vs-sidebar--tooltip">{{ $t(group.i18n) || group.name }}</span>
          </div>

        </template>
      </template>
      <template v-if="group.i18n === 'Facturation'">
        <template v-if="activeUserInfos.privileges.find(item => item === 'facturation')">
          <div @click="clickGroup" class="group-header w-full">
            <span class="flex items-center w-full">

              <!-- Group Icon -->
              <feather-icon
                v-if        = "group.icon  || (this.groupIndex > Math.floor(this.groupIndex))"
                :icon       = "group.icon  || 'CircleIcon'"
                :svgClasses = "{ 'w-3 h-3' : this.groupIndex % 1 != 0 }" />

              <!-- Group Name -->
              <span v-show="!verticalNavMenuItemsMin" class="truncate mr-3 select-none">{{ $t(group.i18n) || group.name }}</span>

              <!-- Group Tag -->
              <vs-chip class="ml-auto mr-4" :color="group.tagColor" v-if="group.tag && !verticalNavMenuItemsMin">{{ group.tag }}</vs-chip>
            </span>

            <!-- Group Collapse Icon -->
            <feather-icon
              v-show     = "!verticalNavMenuItemsMin"
              :class     = "[{'rotate90' : openItems}, 'feather-grp-header-arrow']"
              :icon       = "$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
              svg-classes= "w-4 h-4" />

            <!-- Group Tooltip -->
            <span class="vs-sidebar--tooltip">{{ $t(group.i18n) || group.name }}</span>
          </div>

        </template>
      </template>
      <template v-if="group.i18n === 'Comptabilité'">
        <template v-if="activeUserInfos.privileges.find(item => item === 'comptabilite')">
          <div @click="clickGroup" class="group-header w-full">
            <span class="flex items-center w-full">

              <!-- Group Icon -->
              <feather-icon
                v-if        = "group.icon  || (this.groupIndex > Math.floor(this.groupIndex))"
                :icon       = "group.icon  || 'CircleIcon'"
                :svgClasses = "{ 'w-3 h-3' : this.groupIndex % 1 != 0 }" />

              <!-- Group Name -->
              <span v-show="!verticalNavMenuItemsMin" class="truncate mr-3 select-none">{{ $t(group.i18n) || group.name }}</span>

              <!-- Group Tag -->
              <vs-chip class="ml-auto mr-4" :color="group.tagColor" v-if="group.tag && !verticalNavMenuItemsMin">{{ group.tag }}</vs-chip>
            </span>

            <!-- Group Collapse Icon -->
            <feather-icon
              v-show     = "!verticalNavMenuItemsMin"
              :class     = "[{'rotate90' : openItems}, 'feather-grp-header-arrow']"
              :icon       = "$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
              svg-classes= "w-4 h-4" />

            <!-- Group Tooltip -->
            <span class="vs-sidebar--tooltip">{{ $t(group.i18n) || group.name }}</span>
          </div>

        </template>
      </template>
      <template v-if="group.i18n === 'SMS Marketing'">
        <template v-if="activeUserInfos.privileges.find(item => item === 'sms_marketig')">
          <div @click="clickGroup" class="group-header w-full">
            <span class="flex items-center w-full">

              <!-- Group Icon -->
              <feather-icon
                v-if        = "group.icon  || (this.groupIndex > Math.floor(this.groupIndex))"
                :icon       = "group.icon  || 'CircleIcon'"
                :svgClasses = "{ 'w-3 h-3' : this.groupIndex % 1 != 0 }" />

              <!-- Group Name -->
              <span v-show="!verticalNavMenuItemsMin" class="truncate mr-3 select-none">{{ $t(group.i18n) || group.name }}</span>

              <!-- Group Tag -->
              <vs-chip class="ml-auto mr-4" :color="group.tagColor" v-if="group.tag && !verticalNavMenuItemsMin">{{ group.tag }}</vs-chip>
            </span>

            <!-- Group Collapse Icon -->
            <feather-icon
              v-show     = "!verticalNavMenuItemsMin"
              :class     = "[{'rotate90' : openItems}, 'feather-grp-header-arrow']"
              :icon       = "$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
              svg-classes= "w-4 h-4" />

            <!-- Group Tooltip -->
            <span class="vs-sidebar--tooltip">{{ $t(group.i18n) || group.name }}</span>
          </div>

        </template>
      </template>
      <template v-if="group.i18n === 'Edition & Stat'">
        <template v-if="activeUserInfos.privileges.find(item => item === 'edition_stat')">
          <div @click="clickGroup" class="group-header w-full">
            <span class="flex items-center w-full">

              <!-- Group Icon -->
              <feather-icon
                v-if        = "group.icon  || (this.groupIndex > Math.floor(this.groupIndex))"
                :icon       = "group.icon  || 'CircleIcon'"
                :svgClasses = "{ 'w-3 h-3' : this.groupIndex % 1 != 0 }" />

              <!-- Group Name -->
              <span v-show="!verticalNavMenuItemsMin" class="truncate mr-3 select-none">{{ $t(group.i18n) || group.name }}</span>

              <!-- Group Tag -->
              <vs-chip class="ml-auto mr-4" :color="group.tagColor" v-if="group.tag && !verticalNavMenuItemsMin">{{ group.tag }}</vs-chip>
            </span>

            <!-- Group Collapse Icon -->
            <feather-icon
              v-show     = "!verticalNavMenuItemsMin"
              :class     = "[{'rotate90' : openItems}, 'feather-grp-header-arrow']"
              :icon       = "$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
              svg-classes= "w-4 h-4" />

            <!-- Group Tooltip -->
            <span class="vs-sidebar--tooltip">{{ $t(group.i18n) || group.name }}</span>
          </div>

        </template>
      </template>

      <!-- /Group Label -->

      <!-- Group Items -->
      <ul ref="items" :style="styleItems" class="vs-sidebar-group-items">
        <li v-for="(groupItem, index) in group.submenu" :key="index">
          <!-- If item is group -->
          <template v-if="groupItem.i18n === 'Entête & Pied de page'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'entete_pied_page')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Officines'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'officine')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Personnels'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'personnel')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Utilisateurs'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'utilisateur')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Fonctions'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'fonction')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Sociétés'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'societe')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Banques'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'banque')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Assurances'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'assurance')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Centres Hospitaliers'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'centre_hospitalier')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Prescripteurs'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'prescripteur')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Categories Articles'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'categorie_article')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Professions'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'profession')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Partenaires'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'partenaire')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Fournisseurs'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'fournisseur')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Marques'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'marque')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Types'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'type')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Styles'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'style')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Pays'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'pays')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Villes'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'ville')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Quartiers'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'quartier')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Rôles'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'role')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Rubriques dépenses'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'rubrique_depense')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Fournisseurs verre'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'four_verre')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Catalogue verre'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'catalogue_verre')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Agenda'">
            <template>
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Répertoire'">
            <template>
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Opportunités'">
            <template>
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Suspectes'">
            <template>
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Liste BR'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'liste_br')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Nouveau BR'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'nouveau_br')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'BR Transferés'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'br_transfere')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Stock'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'stock')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Stock verre'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'stock_verre')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Articles transferés'">
            <v-nav-menu-group
              v-if        = "groupItem.submenu"
              :group      = "groupItem"
              :groupIndex = "Number(`${groupIndex}.${index+1}`)"
              :open       = "isGroupActive(groupItem)"
              :openHover  = "openHover" />
            <!-- Else: Item -->
            <v-nav-menu-item
              v-else
              icon-small
              :index  = "groupIndex + '.' + index"
              :to="groupItem.slug !== 'external' ? groupItem.url : null"
              :href="groupItem.slug === 'external' ? groupItem.url : null"
              :icon   = "itemIcon(groupIndex + '.' + index)"
              :slug   = "groupItem.slug"
              :target = "groupItem.target">
                <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
            </v-nav-menu-item>
            <!-- <template v-if="activeUserInfos.privileges.find(item => item === 'stock')">
            </template> -->
          </template>
          <template v-if="groupItem.i18n === 'Inventaire'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'inventaire')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Clients'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'client')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Ordonnances'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'ordonnance')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Proforma'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'proforma')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Ventes'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'vente')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Commandes verres'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'commande')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Montage'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'atelier')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Examen de vue'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'examen_vue')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Caisse recette'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'caisse_recette')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Caisse depense'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'caisse_depense')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Operations'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'operation')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Ventes agence'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'traitement_fact')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Edition Fact.'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'modele_fact')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'facture à déposer'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'modele_fact')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Creance Fact.'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'modele_fact')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Registe d\'opération'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'registre')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Compte'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'compte_sms')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Repertoire'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'repertoire')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Campagne SMS'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'campagne_sms')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Anniv. Client'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'anniv_client')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Anniv. Commande'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'anniv_commande')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'SMS proforma'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'alerte_proforma')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'SMS suivi commande'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'alerte_suivi_commande')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'BR'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'edition_stat')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Détail BR'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'edition_stat')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Stock article'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'edition_stat')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Article sortie'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'edition_stat')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'liste proforma'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'edition_stat')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Détail proforma'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'edition_stat')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Détail commande'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'edition_stat')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'CA'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'edition_ca')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Suivi regl.'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'edition_stat')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'vente'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'edition_suivi_regl')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Facture assurance'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'edition_stat')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Créance assurance'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'edition_stat')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Facturation modèle'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'edition_stat')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Recette'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'edition_caisse_recette')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Depense'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'edition_caisse_depense')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>
          <template v-if="groupItem.i18n === 'Statistiques'">
            <template v-if="activeUserInfos.privileges.find(item => item === 'stat')">
              <v-nav-menu-group
                v-if        = "groupItem.submenu"
                :group      = "groupItem"
                :groupIndex = "Number(`${groupIndex}.${index+1}`)"
                :open       = "isGroupActive(groupItem)"
                :openHover  = "openHover" />

              <!-- Else: Item -->
              <v-nav-menu-item
                class="mb-6"
                v-else
                icon-small
                :index  = "groupIndex + '.' + index"
                :to="groupItem.slug !== 'external' ? groupItem.url : null"
                :href="groupItem.slug === 'external' ? groupItem.url : null"
                :icon   = "itemIcon(groupIndex + '.' + index)"
                :slug   = "groupItem.slug"
                :target = "groupItem.target">
                  <span class="truncate">{{ $t(groupItem.i18n) || groupItem.name }}</span>
                  <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
              </v-nav-menu-item>
            </template>
          </template>


        </li>
      </ul>
      <!-- /Group Items -->
  </div>
</template>


<script>
import VNavMenuItem from './VerticalNavMenuItem.vue'

export default {
  name  : 'v-nav-menu-group',
  props : {
    openHover  : { type: Boolean, default: false },
    open       : { type: Boolean, default: false },
    group      : { type: Object },
    groupIndex : { type: Number }
  },
  components: {
    VNavMenuItem
  },
  data: () => ({
    maxHeight : '0px',
    openItems : false,
    activeUserInfos: null
  }),
  computed: {
    verticalNavMenuItemsMin () { return this.$store.state.verticalNavMenuItemsMin },
    styleItems () {
      return { maxHeight: this.maxHeight }
    },
    itemIcon () {
      return (index) => {
        if (!((index.match(/\./g) || []).length > 1)) return 'CircleIcon'
      }
    },
    isGroupActive () {
      return (item) => {
        const path        = this.$route.fullPath
        let open          = false
        const routeParent = this.$route.meta ? this.$route.meta.parent : undefined

        const func = (item) => {
          if (item.submenu) {
            item.submenu.forEach((item) => {
              if ((path === item.url || routeParent === item.slug) && item.url) { open = true } else if (item.submenu) { func(item) }
            })
          }
        }

        func(item)
        return open
      }
    }
  },
  watch: {
    // OPEN & CLOSES DROPDOWN ON ROUTE CHANGE
    '$route' () {
      if (this.verticalNavMenuItemsMin) return

      const scrollHeight = this.scrollHeight

      // Collapse Group
      if (this.openItems && !this.open) {

        this.maxHeight = `${scrollHeight}px`
        setTimeout(()  => {
          this.maxHeight = `${0}px`
        }, 50)

      // Expand Group
      } else if (this.open) {

        this.maxHeight = `${scrollHeight}px`
        setTimeout(()  => {
          this.maxHeight = 'none'
        }, 300)
      }
    },
    maxHeight () {
      this.openItems = this.maxHeight !== '0px'
    },
    // OPEN AND CLOSES DROPDOWN MENU ON NavMenu COLLAPSE AND DEFAULT VIEW
    '$store.state.verticalNavMenuItemsMin' (val) {
      const scrollHeight = this.$refs.items.scrollHeight

      if (!val && this.open) {

        this.maxHeight = `${scrollHeight}px`
        setTimeout(()  => {
          this.maxHeight = 'none'
        }, 300)
      } else {

        this.maxHeight = `${scrollHeight}px`
        setTimeout(()  => {
          this.maxHeight = '0px'
        }, 50)
      }
      if (val && this.open) {

        this.maxHeight = `${scrollHeight}px`
        setTimeout(()  => {
          this.maxHeight = '0px'
        }, 250)
      }
    }
  },
  methods: {
    clickGroup () {
      if (!this.openHover) {

        const thisScrollHeight = this.$refs.items.scrollHeight

        if (this.maxHeight === '0px') {
          this.maxHeight = `${thisScrollHeight}px`
          setTimeout(() => {
            this.maxHeight = 'none'
          }, 300)

        } else {
          this.maxHeight = `${thisScrollHeight}px`
          setTimeout(() => {
            this.maxHeight = `${0}px`
          }, 50)
        }

        this.$parent.$children.map((child) => {
          if (child.isGroupActive) {
            if (child !== this && !child.open && child.maxHeight !== '0px') {
              setTimeout(() => {
                child.maxHeight = `${0}px`
              }, 50)
            }
          }
        })
      }
    },
    mouseover () {
      if (this.openHover) {
        const scrollHeight = this.$refs.items.scrollHeight
        this.maxHeight   = `${scrollHeight}px`
      }
    },
    mouseout () {
      if (this.openHover) {
        const scrollHeight = 0
        this.maxHeight   = `${scrollHeight}px`
      }
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  mounted () {
    this.openItems = this.open
    if (this.open) { this.maxHeight = 'none' }
  },
  created () {
    this.activeUserInfo()
  }
}

</script>


<style lang="scss">
@import "@/assets/scss/vuexy/components/verticalNavMenuGroup.scss"
</style>
