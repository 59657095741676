/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  // {
  //   url: "/apps/email",
  //   name: "Email",
  //   slug: "email",
  //   icon: "MailIcon",
  //   i18n: "Email",
  // },
  {
    url: '/dashboard',
    name: 'Dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard'
  },

  //******************************************************************** */
  // Parametrage
  //******************************************************************** */

  {
    url: null,
    name: 'Parametrage',
    icon: 'SettingsIcon',
    i18n: 'Paramétrage',
    submenu: [
      {
        url: '/apps/config-facture/entete-piedpage',
        name: 'Entête & Pied de page',
        i18n: 'Entête & Pied de page'
      },
      {
        url: '/apps/officine/officine-list',
        name: 'Officines',
        i18n: 'Officines'
      },

      {
        url: '/apps/personnel/personnel-list',
        name: 'Personnels',
        i18n: 'Personnels'
      },

      {
        url: '/apps/user/user-list',
        name: 'Utilisateurs',
        icon: 'UsersIcon',
        i18n: 'Utilisateurs'
      },

      {
        url: '/apps/fonction/fonction-list',
        name: 'Fonctions',
        i18n: 'Fonctions'
      },

      {
        url: '/apps/societes/societes-list',
        name: 'Societes',
        i18n: 'Sociétés'
      },

      {
        url: '/apps/banques/banques-list',
        name: 'Banques',
        i18n: 'Banques'
      },

      {
        url: '/apps/assurances/assurances-view',
        name: 'Assurances',
        i18n: 'Assurances'
      },

      {
        url: '/apps/centre_hopitalier/centre_hopitalier-list',
        name: 'Centres Hospitaliers',
        i18n: 'Centres Hospitaliers'
      },

      {
        url: '/apps/prescripteur/prescripteur-list',
        name: 'Prescripteurs',
        i18n: 'Prescripteurs'
      },

      {
        url: '/apps/categorie_article/categorie_article-list',
        name: 'Categories Articles',
        i18n: 'Categories Articles'
      },

      {
        url: '/apps/profession/profession-view',
        name: 'Professions',
        i18n: 'Professions'
      },


      {
        url: '/apps/partenaire/partenaire-list',
        name: 'Partenaires',
        i18n: 'Partenaires'
      },

      {
        url: '/apps/fournisseur/fournisseur-view',
        name: 'Fournisseurs',
        i18n: 'Fournisseurs'
      },

      {
        url: '/apps/marque/marques-list',
        name: 'Marques',
        i18n: 'Marques'
      },

      {
        url: '/apps/type/type-list',
        name: 'Types',
        i18n: 'Types'
      },

      {
        url: '/apps/style/style-list',
        name: 'Styles',
        i18n: 'Styles'
      },


      {
        url: '/apps/pays/pays-list',
        name: 'Pays',
        i18n: 'Pays'
      },

      {
        url: '/apps/ville/ville-list',
        name: 'Villes',
        i18n: 'Villes'
      },

      {
        url: '/apps/quartier/quartier-list',
        name: 'Quartiers',
        i18n: 'Quartiers'
      },

      {
        url: '/apps/roles',
        name: 'Roles',
        i18n: 'Rôles'
      },
      {
        url: '/apps/rubrique-depense',
        name: 'Rubriques dépenses',
        i18n: 'Rubriques dépenses'
      },

      {
        url: '/apps/fournisseurverre/fournisseurverre-list',
        name: 'Fournisseurs verre',
        i18n: 'Fournisseurs verre'
      },
      {
        url: '/apps/fournisseurverre/catalogue-verre',
        name: 'Catalogue verre',
        i18n: 'Catalogue verre'
      }

    ]
  },

  // {
  //   url: null,
  //   name: 'Commercial',
  //   icon: 'BriefcaseIcon',
  //   i18n: 'Commercial',
  //   submenu: [
  //     {
  //       url: '/commercial/agenda',
  //       name: 'Agenda',
  //       i18n: 'Agenda'
  //     },
  //     {
  //       url: '/commercial/repertoire',
  //       name: 'Repertoire',
  //       i18n: 'Répertoire'
  //     },
  //     {
  //       url: '/commercial/opportunites',
  //       name: 'Opportunites',
  //       i18n: 'Opportunités'
  //     },
  //     {
  //       url: '/commercial/suspects',
  //       name: 'Suspectes',
  //       i18n: 'Suspectes'
  //     }
  //   ]
  // },


  //******************************************************************** */
  // Gestion de stock
  //******************************************************************** */


  {
    url: null,
    name: 'Gestion de stock',

    icon: 'DatabaseIcon',
    i18n: 'Gestion de stock',
    submenu: [
      {
        url: '/apps/commandefourn/add',
        name: 'Nouveau BR',
        i18n: 'Nouveau BR'
      },
      {
        url: '/apps/commandefourn/commandefourn-list',
        name: 'Liste BR',
        i18n: 'Liste BR'
      },
      {
        url: '/apps/commandefourn/commandefourn-transferer',
        name: 'BR Transferes',
        i18n: 'BR Transferés'
      },

      {
        url: '/apps/stock',
        name: 'stock',
        i18n: 'Stock'
      },

      {
        url: '/apps/stock-verre',
        name: 'stock verre',
        i18n: 'Stock verre'
      },

      {
        url: '/apps/stock/articles-transferer',
        name: 'Articles_transferes',
        i18n: 'Articles transferés'
      },
      {
        url: '/apps/inventaire',
        name: 'inventaire',
        i18n: 'Inventaire'
      }

    ]

  },

  //******************************************************************** */
  // Exploitation'
  //******************************************************************** */

  {
    url: null,
    name: 'Exploitation',

    icon: 'RepeatIcon',
    i18n: 'Exploitation',
    submenu: [

      {
        url: '/apps/client/client-list',
        name: 'Clients',
        i18n: 'Clients'
      },

      {
        url: '/apps/ordonnance/ordonnance-list',
        name: 'Ordonnances',
        i18n: 'Ordonnances'
      },

      {
        url: '/apps/proformaclient/proforma-list',
        name: 'Proforma',
        i18n: 'Proforma'
      },

      {
        url: '/apps/commandeclient/commande-list',
        name: 'Ventes',
        i18n: 'Ventes'
      },

      {
        url: '/apps/commandes-verres',
        name: 'Commande verre',
        i18n: 'Commandes verres'
      },

      {
        url: '/apps/atelier',
        name: 'Montage',
        i18n: 'Montage'
      },

      {
        url: '/apps/examen-vue',
        name: 'Examen de vue',
        i18n: 'Examen de vue'
      }


    ]

  },

  //******************************************************************** */
  // Caisse
  //******************************************************************** */


  {
    url: null,
    name: 'Caisse',
    icon: 'DollarSignIcon',
    i18n: 'Caisse',
    submenu: [
      {
        url: '/apps/caisse',
        name: 'Caisse recette',
        i18n: 'Caisse recette'
      },
      {
        url: '/apps/depense',
        name: 'Caisse depense',
        i18n: 'Caisse depense'
      },
      {
        url: '/apps/operations',
        name: 'Operations',
        i18n: 'Operations'
      }

    ]

  },
  //******************************************************************** */
  // Facturation
  //******************************************************************** */

  {
    url: null,
    name: 'Facturation',
    icon: 'FileTextIcon',
    i18n: 'Facturation',
    submenu: [
      {
        url: '/facturation/vente',
        name: 'Ventes agence',
        i18n: 'Ventes agence'
      },
      {
        url: '/apps/model-facture',
        name: 'Edition Fact.',
        i18n: 'Edition Fact.'
      },
      {
        url: '/apps/facture-a-deposer',
        name: 'facture à déposer',
        i18n: 'facture à déposer'
      },
      {
        url: '/apps/reglement-fact',
        name: 'Creance Fact.',
        i18n: 'Creance Fact.'
      }

    ]

  },

  //******************************************************************** */
  // Comptabilité
  //******************************************************************** */

  {
    url: null,
    name: 'Comptabilite',
    icon: 'FolderIcon',
    i18n: 'Comptabilité',
    submenu: [
      {
        url: '/apps/compta/',
        name: 'Registe operation',
        i18n: 'Registe d\'opération'
      }
    ]

  },

  //******************************************************************** */
  // SMS MARKETING
  //******************************************************************** */

  {
    url: null,
    name: 'SMS Marketing',
    icon: 'SendIcon',
    i18n: 'SMS Marketing',
    submenu: [
      {
        url: '/apps/sms-marketing/compte',
        name: 'Compte',
        i18n: 'Compte'
      },
      {
        url: '/apps/sms-marketing/repertoire',
        name: 'Repertoire',
        i18n: 'Repertoire'
      },
      {
        url: '/apps/sms-marketing/campagne-sms',
        name: 'Campagne SMS',
        i18n: 'Campagne SMS'
      },
      {
        url: '/apps/sms-marketing/anniv-client',
        name: 'Anniv. Client',
        i18n: 'Anniv. Client'
      },
      {
        url: '/apps/sms-marketing/anniv-commande',
        name: 'Anniv. Commande',
        i18n: 'Anniv. Commande'
      }
    ]

  },

  //******************************************************************** */
  // EDITION & STAT
  //******************************************************************** */

  {
    url: null,
    name: 'Edition & Stat',
    icon: 'ActivityIcon',
    i18n: 'Edition & Stat',
    submenu: [
      {
        url: '/apps/edition-stat/br',
        name: 'BR',
        i18n: 'BR'
      },
      {
        url: '/apps/edition-stat/stock-article',
        name: 'Stock article',
        i18n: 'Stock article'
      },
      {
        url: '/apps/edition-stat/article-sortie',
        name: 'Article sortie',
        i18n: 'Article sortie'
      },
      {
        url: '/apps/edition-stat/liste-proforma',
        name: 'liste proforma',
        i18n: 'liste proforma'
      },
      {
        url: '/apps/edition-stat/suivi-cmmd',
        name: 'vente',
        i18n: 'vente'
      },
      {
        url: '/apps/edition-stat/suivi-regl',
        name: 'Suivi regl.',
        i18n: 'Suivi regl.'
      },
      {
        url: '/apps/edition-stat/facture-assurance',
        name: 'Facture assurance',
        i18n: 'Facture assurance'
      },
      {
        url: '/apps/edition-stat/creance-assurance',
        name: 'Créance assurance',
        i18n: 'Créance assurance'
      },
      {
        url: '/apps/edition-stat/caisse-recette',
        name: 'Recette',
        i18n: 'Recette'
      },
      {
        url: '/apps/edition-stat/caisse-depense',
        name: 'Depense',
        i18n: 'Depense'
      },
      {
        url: '/apps/edition-stat/depense-rubrique',
        name: 'Dépense/Rubriques',
        i18n: 'Dépense/Rubriques'
      },
      {
        url: '/apps/edition-stat/ca',
        name: 'CA',
        i18n: 'CA'
      },
      {
        url: '/apps/edition-stat/statistiques',
        name: 'Statistiques',
        i18n: 'Statistiques'
      }

    ]

  }
]

